import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
  providers: [ConfirmationService, DialogService]
})
export class LegalComponent implements OnInit {

  //Títulos
  title: string;
  caption: string;

  /**
   * Marca de operación en curso
   */
   public loaded: boolean;

  constructor(private locale: LocalePipe,
    private loadingService: LoadingService,
    public dialogService: DialogService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // Añadir titulos a la cabecera
    this.title = this.locale.transform('legal_title');
    this.caption = this.locale.transform('legal_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;
    
    // ocultar pantalla de carga
    setTimeout(() => {
      this.loadingService.hideLoading();
    });
  }

  // LLEVAR A COMPONENTE O A PADRE
  /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
   childSelection(value: string) {
    switch(value) {
      case "login": {

        // Abrir pantalla de inicio de sesión en forma modal
        const ref = this.dialogService.open(LoginComponent, {
          width: '50%',
          showHeader: false,
          dismissableMask: true,
          styleClass: 'login-dialog'
        });

        ref.onClose.subscribe((userDo: boolean) => {
          if (userDo) {
            console.log("Logueado");
          }
          else {
            console.log("dismissed");
          }
        });
        break;
      }
      case "register": {

        // Abrir pantalla de registro de usuario en forma modal
        const ref = this.dialogService.open(RegisterComponent, {
          width: '80%',
          showHeader: false,
          dismissableMask: true
        });

        ref.onClose.subscribe((userDo: boolean) => {
          if (userDo) {
            
          }
          else {
            
          }
        });
        break;
      }
      case "logout": {
        this.confirmationService.confirm({
          message: '¿Estás seguro que deseas cerrar sesión?',
          header: 'Cerrar sesión',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'CONTINUAR',
          rejectVisible: false,
          acceptButtonStyleClass: 'dialog-button',
          accept: () => {

            this.loadingService.setLoading();

              this.authService.logout(
                (resp: boolean) => {

                  // ocultar loader dinámico
                  this.loadingService.hideLoading();

                  // actualizar sesión
                  this.sessionService.saveSessionInfo(null);

                  // borrar sesión persistente
                  LocalStorageUtils.clear();

                  // reiniciar la navegación
                  this.router.navigate(['home']);
                },
                (resp: boolean, err: HttpErrorResponse) => {

                });
          },
          reject: () => {}
      });
        break;
      }
      default:
        break;
    }
  }

}
