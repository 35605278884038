<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <div class="login-content">
        <div class="login-content-overlay">
            <div class="login-box card">
                <div class="login-container">
                    <img src="../../../assets/img/logo_todo.png" routerLink="/home" alt="Inicia sesión"/>
                    <div class="title">Inicia sesión con tus credenciales de usuario</div>
                    <div class="form-content">
                        <form [formGroup]="form" novalidate autocomplete="off" (submit)="login()">
                            <div class="item-line">
                                <input [readOnly]="inuse" formControlName="email" id="emailUsuario" placeholder="{{'login_emailUsuario'|locale}}" type="text" required>
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <!--TODO: candidata a componente-->
                                <ng-container *ngIf="form.invalid && form.controls['email'].invalid && (submitted || form.controls['email'].dirty)">
                                    <span *ngIf="form.controls['email'].errors?.required">{{'login_errors_required'|locale}}</span>
                                    <span *ngIf="form.controls['email'].errors?.pattern">{{'login_errors_email'|locale}}</span>
                                </ng-container>
                            </div>
                            <div class="item-line">
                                <!--vincular campo contraseña de usuario-->
                                <input [readOnly]="inuse" formControlName="clave" id="claveUsuario" placeholder="{{'login_claveUsuario'|locale}}" type="password" required appPassword>
                                <!--pintar errores en campo si formulario enviado o campo modificado-->
                                <!--TODO: candidata a componente-->
                                <ng-container *ngIf="form.invalid && form.controls['clave'].invalid && (submitted || form.controls['clave'].dirty)">
                                    <span *ngIf="form.controls['clave'].errors?.required">{{'login_errors_required'|locale}}</span>
                                    <span *ngIf="form.controls['clave'].errors?.minlength">{{'login_errors_minlength'|locale}}</span>
                                    <span *ngIf="form.controls['clave'].errors?.maxlength">{{'login_errors_maxlength'|locale}}</span>
                                </ng-container>
                            </div>
                            <a routerLink="/recover">¿Has olvidado tu contraseña?</a>
                            <!--habilitar envío si formulario válido-->
                            <input [disabled]="inuse||!form.valid" value="{{'login_submit'|locale}}" type="submit">

                            <div class="signup-content">
                                <div>¿Aún no tienes cuenta?<a routerLink="/register"><b>Regístrate aquí</b></a></div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
