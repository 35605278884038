// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  applicationName: 'com.onlinepro.mobile.credencial',
  apiLoginUrl: 'https://api-credencial.hardd-dev.com/api/auth/login',
  apiCheckUrl: 'https://api-credencial.hardd-dev.com/api/auth/check',
  apiUsuarioGetUrl: 'https://contrall-projct.ey.r.appspot.com/api/auth/usuarios/',
  apiUsuarioUpdateUrl: 'https://contrall-projct.ey.r.appspot.com/api/auth/usuarios/update/',
  apiLogoutUrl: 'https://api-credencial.hardd-dev.com/api/auth/logout',
  apiRegisterUrl: 'https://api-credencial.hardd-dev.com/api/auth/registro',
  apiRecoverUrl: 'https://api-credencial.hardd-dev.com/api/auth/resetPassword',
  apiConfirmUrl: 'https://api-credencial.hardd-dev.com/api/auth/confirmReset',
  apiServiciosListUrl: 'https://api-credencial.hardd-dev.com/api/v1/servicios/listarServiciosAplicacion',
  apiServicioCategoriasListUrl: 'https://api-credencial.hardd-dev.com/api/v1/servicios/categoria/listarCategoriasTipo',
  apiCredencialesListUrl: 'https://api-credencial.hardd-dev.com/api/v1/credencial/listarCredencialesUsuario',
  apiCredencialUrl: 'https://api-credencial.hardd-dev.com/api/v1/credencial/',
  apiSelllosListUrl: 'https://api-credencial.hardd-dev.com/api/v1/credencial/listarSellosCredencial',
  apiServicioUrl: 'https://api-credencial.hardd-dev.com/api/v1/servicios/',
  apiCredencialPdfUrl: 'https://api-credencial.hardd-dev.com/api/v1/credencial/pdf',
  apiCredencialAlbumUrl: 'https://api-credencial.hardd-dev.com/api/v1/credencial/generarAlbumCredencial'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
