<div class="footer-content">
    <div class="footer-copy">
        <span>@2022 Federacion Espanola de Asociaciones de Amigos del Camino de Santiago</span>
        <div class="policies-content">
            <a routerLink="/legal">Aviso legal</a> | <a routerLink="/cookies">Política de cookies</a> 
        </div>
    </div>
    <div class="footer-logo">
        <img src="../../../assets/img/logo-camin-white.png"/>
    </div>
    <div class="footer-rrss">
        <div id="sub-icon" class="social-icons-subnav">
            <a href="https://www.facebook.com/revistaperegrino?fref=ts"><fa-icon [icon]="faFacebook"></fa-icon></a>
            <a href="https://twitter.com/Federacion_CS"><fa-icon [icon]="faTwitter"></fa-icon></a>
        </div>
    </div>
</div>