<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="legal-content">
        <div class="container">
            <h2>Condiciones Legales y Política de Privacidad</h2>
            <h2>Credencial Digital</h2>
            <h4>1. Objeto</h4>
            <p><b>1.1 </b>El presente documento contiene las condiciones legales aplicables a la aplicación para
                dispositivos móviles "Credencial" (en adelante, “App”), disponible
                en los espacios App Store y Google Play, para las versiones de sistemas operativos y
                dispositivos móviles indicados. Asimismo, determinados contenidos podrán ser accesibles a
                través del área privada de la web.</p>
            <p>Dichas aplicaciones no accederán a los datos de contactos, si bien recopilarán la fecha, hora y
                duración de los accesos a las mismas. El usuario deberá permitir el almacenamiento y
                modificación de datos en el dispositivo, el uso de servicios de conectividad y los
                requerimientos y configuraciones adicionales que se indiquen en la descarga, instalación y
                activación de dichas aplicaciones. Para poder mostrar la ruta o guía a servicios categorizados,
                deberá permitir el acceso del App a su ubicación/geoposicionamiento determinado por el
                terminal y, si lo desea, el acceso a la cámara para poder realizar fotografías y posteriormente
                almacenarlas en su área privada.</p>
            <p>Para la obtención de las Credenciales Digitales será necesario registrarse y
                permitir el acceso a la cámara y a la ubicación/geoposicionamiento para verificar el
                cumplimiento de las condiciones y la obtención de los sellos.</p>
            <p><b>1.2 </b>Los servicios disponibles a través de la App son prestados por CAMINO APLICACIÓN S.L., con CIF B74437690 y dirección en CALLE BERNARDO ÁLVAREZ GALÁN 3 33405 - CASTRILLÓN (ASTURIAS),
                , tras la verificación del cumplimiento de los requisitos y condiciones exigibles.</p>
            <p><b>1.3 </b>La App permite el acceso a una parte pública, con información general sobre el Camino
                y agenda de actividades, información sobre servicios por categorías, identificados en
                un mapa que permite trazar una guía al destino (en caso de tener activada la
                ubicación/geolocalización), y secciones configurables. Además, se incluye un botón SOS que
                realiza una llamada al servicio de urgencias (112).</p>
            <p>La parte privada está relacionada con la obtención de las Credenciales Digitales, incluye un registro y la
                compra con códigos, información sobre las Credenciales y sus modalidades, y se utiliza para acreditar el cumplimiento de los requisitos para la consecución de éstos
                tras la obtención de los distintos sellos mediante la realización de fotografías en el entorno de
                los enclaves determinados. Una vez validada la Credencial, se generará automáticamente el
                Certificado, descargable desde el área privada de la web.</p>
            <p>El usuario puede además realizar fotografías de sus experiencias, que se almacenarán en la App y en el
                área privada accesible desde la web. El área privada también permite descargar los Certificados, que incluirán su nombre completo, lugar de inicio, fecha y
                hora de salida y de validación del mismo. El usuario podrá compartir la
                experiencia en redes sociales (Instagram, Facebook, Twitter, WhatsApp y LinkedIn),
                lo que implica la activación de los correspondientes conectores, la vinculación con las cuentas
                del usuario en dichos espacios y el envío y publicación de contenidos e imágenes a través de
                las mismas.</p>
            <p><b>1.4 </b>Los servicios de la App están disponible para personas mayores de 16 años de edad que no
                estén legalmente incapacitadas. El usuario en todo caso debe aceptar de forma completa y sin
                reservas de estas condiciones legales, garantizando que no existe limitación o
                condicionamiento alguno que le impida el uso de estos servicios.</p>
            <h4>2. Protección de Datos</h4>
            <p><b>2.1 </b>A través de la App se recogen y tratan datos de carácter personal para gestionar el
                funcionamiento de la aplicación y sus servicios, para lo cual deberá establecer los
                correspondientes permisos en la descarga e instalación de la misma. Si desea hacer uso de
                determinadas funciones, deberá autorizar a la aplicación a acceder a la cámara, a funciones de
                búsqueda, notificaciones y actualizaciones o geolocalización.</p>
            <p>Los datos vinculados al uso de la aplicación en su parte pública así como para el área privada
                del usuario serán tratados por CAMINO APLICACIÓN S.L., para gestionar el
                funcionamiento de la aplicación, la puesta a disposición y/o envío de notificaciones o
                comunicaciones sobre actividades relacionadas con el Camino de Santiago y demás Caminos. En caso de que lo autorice o solicite expresamente mediante
                la suscripción a nuestro boletín o newsletter, le enviaremos comunicaciones electrónicas que
                podrían incluir contenidos comerciales o promocionales, ofertas, productos o servicios de
                terceros relacionados con el Camino. Igualmente, podremos realizar
                análisis y estadísticas del uso de la aplicación de forma agregada y global.</p>
            <p>Los datos serán conservados por los responsables del tratamiento durante el tiempo necesario
                para la realización de las gestiones que correspondan con arreglo a las finalidades señaladas, y
                posteriormente y debidamente bloqueados hasta la prescripción de las posibles
                responsabilidades legales que deriven de lo anterior. Los mencionados tratamientos de datos
                son independientes.</p>
            <p><b>2.2 </b>La base jurídica de los tratamientos anteriores es el consentimiento del usuario en relación
                al alta/registro y funcionamiento de la aplicación, y para el envío de comunicaciones
                comerciales electrónicas; y el cumplimiento y adopción de medidas precontractuales o
                contractuales en cuanto la obtención de las Credenciales. En los casos
                en que aparezcan campos marcados con asterisco (*), dichos datos serán de cumplimentación
                obligatoria, sin los cuales no se dará trámite a la petición correspondiente.</p>
            <p>La persona que remita sus datos por las vías señaladas manifiesta y garantiza que la totalidad
                de la información facilitada, es veraz, correcta y completa, se encuentra actualizada, y que va
                referida a su persona.</p>
            <p><b>2.3 </b>Los datos serán tratados de manera confidencial y sobre los mismos se aplicarán medidas
                técnicas y organizativas adecuadas y suficientes que garanticen la privacidad y confidencialidad
                de los mismos. No se prevén transferencias internacionales de datos.</p>
            <p><b>2.4 </b>Los afectados podrán ejercitar en cualquier momento los derechos de acceso, rectificación,
                oposición, supresión, limitación al tratamiento y portabilidad de sus datos, en las condiciones
                legalmente previstas y dirigiéndose por escrito a soporte@credencialdigitalperegrino.com
                (en las Oficinas de Turismo correspondientes). Asimismo, le informamos de que le
                asiste el derecho a efectuar una reclamación ante las autoridades de protección de datos en
                caso de que considere que el tratamiento de sus datos no es el adecuado.</p>
            <h4>3. Cambios en las condiciones legales</h4>
            <p>CAMINO APLICACIÓN S.L. se reserva el derecho a efectuar, en cualquier
                momento, modificaciones y actualizaciones en las condiciones y servicios prestados por medio
                de la App. Las correspondientes modificaciones serán comunicadas al usuario, que deberá
                aceptarlas para continuar utilizando estos servicios.</p>
            <h4>4. Baja y cancelación de la cuenta</h4>
            <p><b>4.1 </b>El usuario podrá cancelar su registro/cuenta en la App en cualquier momento, mediante
                los parámetros correspondientes en su perfil de usuario.</p>
            <p><b>4.2 </b>Dicha cancelación implicará el bloqueo del acceso a la parte privada de la App y la
                supresión de sus datos en los términos previstos en la normativa de protección de datos. Los
                datos serán conservados al menos durante el tiempo que sea necesario para llevar a cabo los
                trámites correspondientes, y posteriormente y debidamente bloqueados hasta la prescripción
                de las responsabilidades y efectos que procedan.</p>
        </div>
    </section>
    <app-footer></app-footer>
</div>
