<div class="main-content">
    <app-header [active]=0 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="credential-detail-content">
        <div class="title-content" *ngIf="credencial != null">
            <h2>Credencial del {{credencial.fechaCompra | date: 'dd/MM/yyyy'}}</h2>
            <div class="state-unvalidate" *ngIf="credencial.estado == 0">CREDENCIAL SIN VALIDAR</div>
            <div class="state-validate" *ngIf="credencial.estado != 0">CREDENCIAL VALIDADA</div>
            <span class="subtitle">{{credencial.camino.nombre}}, {{credencial.medio.nombre}}</span>
            
        </div>
        <p-gmap #gmap (onMapReady)="setMap($event)" [options]="options" [overlays]="markers" [style]="{'width':'100%','height':'300px'}"></p-gmap>
        <div class="sellos-title-content">
            <div class="title-content">
                <h3>Sellos realizados</h3>
                <span class="subtitle"><b>{{sellos.length}}</b> sello/s</span>
            </div>
            <div class="actions-content">
                <button class="print-button" (click)="printCredencial()" *ngIf="credencial.estado != 0"><i class="pi pi-id-card"></i>IMPRIMIR CERTIFICADO</button>
                <!--<button class="printed-button" *ngIf="credencial.estado == 1 && credencial.impresion == 1" disabled><i class="pi pi-check"></i>CREDENCIAL IMPRESA</button>-->
                <button class="print-album" (click)="printAlbum()" *ngIf="sellos.length > 0"><i class="pi pi-book"></i>IMPRIMIR ÁLBUM</button>
            </div>
        </div>
        <div class="sellos-content">
            <div class="single-sello-content" *ngFor="let sello of sellos">
                <p-card (click)="openSello(sello)">
                    <img [src]="sello.imagen" alt="Imagen del sello"/>
                </p-card>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>
