import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faBars, faLock, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { SessionService } from 'src/app/services/session.service';
import { LocalePipe } from 'src/app/pipes/locale.pipe';

import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() active: number;

  @Output() newItemEvent = new EventEmitter<string>();

  faUser = faUser;
  faLock = faLock;
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  faLogout = faSignOutAlt;
  faMenu = faBars;

  collapsed = true;

  @ViewChild('stickyMenu') menuElement: ElementRef;

  sticky: boolean = false;
  elementPosition: any;

  name: string = '';
  lastName: string = '';

  constructor(
    public sessionService: SessionService,
    private locale: LocalePipe) { }

  ngOnInit(): void {
    if (this.sessionService.loadSessionInfo() != null && this.sessionService.loadSessionInfo().usuario != null && this.sessionService.loadSessionInfo().usuario != undefined) {
      this.name = this.sessionService.loadSessionInfo().usuario.nombre;
      this.lastName = this.sessionService.loadSessionInfo().usuario.apellidos;
    }
  }

  ngAfterViewInit() {
    if (this.menuElement != null) {
      this.elementPosition = this.menuElement.nativeElement.offsetTop;
    }
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if(windowScroll > 100) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  passDataToParent(value: string) {
    this.newItemEvent.emit(value);
  }
}
