import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  providers: [ConfirmationService]
})
export class FaqComponent implements OnInit {

  //Títulos
  title: string;
  caption: string;

  /**
  * Marca de operación en curso
  */
  public loaded: boolean;

  items: MenuItem[];
  activeItem: MenuItem;

  constructor(private locale: LocalePipe,
    private loadingService: LoadingService,
    private modalService: NgbModal,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // Añadir titulos a la cabecera
    this.title = this.locale.transform('faq_title');
    this.caption = this.locale.transform('faq_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    this.items = [
      {label: 'Home', icon: 'pi pi-fw pi-home'},
      {label: 'Calendar', icon: 'pi pi-fw pi-calendar'},
      {label: 'Edit', icon: 'pi pi-fw pi-pencil'},
      {label: 'Documentation', icon: 'pi pi-fw pi-file'},
      {label: 'Settings', icon: 'pi pi-fw pi-cog'}
  ];
  
  this.activeItem = this.items[0];
    
    // ocultar pantalla de carga
    setTimeout(() => {
      this.loadingService.hideLoading();
    });
  }

  // LLEVAR A COMPONENTE O A PADRE
  /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
  childSelection(value: string) {
    switch(value) {
      case "login": {

        // Abrir pantalla de inicio de sesión en forma modal
        const modalRef = this.modalService.open(LoginComponent, { centered: true, size: 'lg' });

        // Ejecutor al finalizar el proceso de login
        modalRef.result.then(result => {
          if (result) {
            console.log("Logueado");
          }
        },
        () => {
          console.log("dismissed");
        });
        break;
      }
      case "register": {

        // Abrir pantalla de registro de usuario en forma modal
        const modalRef = this.modalService.open(RegisterComponent, { centered: true, size: 'lg' });

        // Ejecutor al finalizar el proceso de registro
        modalRef.result.then(result => {
          if (result) {
            console.log("Registrado");
          }
        },
        () => {
          console.log("dismissed");
        });
        break;
      }
      case "logout": {
        this.confirmationService.confirm({
          message: '¿Estás seguro que deseas cerrar sesión?',
          header: 'Cerrar sesión',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'CONTINUAR',
          rejectVisible: false,
          acceptButtonStyleClass: 'dialog-button',
          accept: () => {

            this.loadingService.setLoading();

              this.authService.logout(
                (resp: boolean) => {

                  // ocultar loader dinámico
                  this.loadingService.hideLoading();

                  // actualizar sesión
                  this.sessionService.saveSessionInfo(null);

                  // borrar sesión persistente
                  LocalStorageUtils.clear();

                  // reiniciar la navegación
                  this.router.navigate(['home']);
                },
                (resp: boolean, err: HttpErrorResponse) => {

                });
          },
          reject: () => {}
        });
        break;
      }
      default:
        break;
    }
  }

}
