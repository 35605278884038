<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header [active]=0 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="project-content">
        <div class="container">
            <h2>La Credencial de <b>siempre</b>, ahora con un nuevo <b>formato</b></h2>
            <p>El Camino de Santiago es un camino de peregrinación cristiana abierto a todas las gentes y cuya meta es la Catedral de Santiago de Compostela, donde se venera el Sepulcro del Apóstol Santiago desde su descubrimiento en el siglo XIX.</p>
            <p>La "Credencial del Peregrino" es el documento que acredita tu condición de peregrino jacobeo como tal, por lo que deben figurar en ella tus datos personales y los relacionados con tu peregrinación.</p>
            <p>La "Credencial" te sirve para documentar tu peregrinar, sellándola al inicio y a lo largo del Camino en las localidades donde pernoctes. Si sólo realizas los últimos 100 km (a pie, caballo) ó 200 km (en bici) deberás sellar la credencial dos veces cada día.</p>
            <p>Hacer la peregrinación a Santiago es un compromiso personal de quien lo realiza: los voluntarios que encuentres (asociaciones, cofradías, albergues, etc) están ahí para hacer más fácil el camino a los peregrinos y nunca a tu servicio personal.</p>
            <p>Recorre el Camino con la mente abierta disfrutando de su paisaje, historia y arte; de sus poblaciones, gentes y de tus compañeros peregrinos. Sé prudente en entornos de peligro (carretera, montaña, etc) o climatología adversa (calor, frío, lluvia, etc). No pretendas caminar a un paso que no sea el tuyo. Respeta el medioambiente (nada de desperdicios) y aporta tu "granito de arena" en la lucha contra el cambio climático (ahorra energía). Y marcha atento a la ayuda mutua y a los "pequeños milagros" del Camino.</p>
            <p>Cuando llegues a Compostela podrás venerar al Apóstol en su Catedral mediante el tradicional "abrazo" y orar ante sus reliquias. Luego, ya tendrás tiempo de estampar el sello final en tu "Credencial del Peregrino", solicitar la "Compostela" u otro documento que certifique tu peregrinación, reecontrarte con tus compañeros peregrinos e intercambiar emociones y pensamientos jacobeos.</p>
        </div>
    </section>
    <app-footer></app-footer>
</div>