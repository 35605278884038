import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from 'primeng/dynamicdialog';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { LoadingService } from 'src/app/services/loading.service';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  providers: [DialogService]
})
export class ProjectComponent implements OnInit {

  //Títulos
  title: string;
  caption: string;

  /**
   * Marca de operación en curso
   */
  public loaded: boolean;

  constructor(private locale: LocalePipe,
    private loadingService: LoadingService,
    private modalService: NgbModal,
    public dialogService: DialogService) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // Añadir titulos a la cabecera
    this.title = this.locale.transform('project_title');
    this.caption = this.locale.transform('project_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;
    
    // ocultar pantalla de carga
    setTimeout(() => {
      this.loadingService.hideLoading();
    });
  }

  // LLEVAR A COMPONENTE O A PADRE
  /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
   childSelection(value: string) {
    switch(value) {
      case "login": {

        // Abrir pantalla de inicio de sesión en forma modal
        const ref = this.dialogService.open(LoginComponent, {
          width: '50%',
          showHeader: false,
          dismissableMask: true,
          styleClass: 'login-dialog'
        });

        ref.onClose.subscribe((userDo: boolean) => {
          if (userDo) {
            console.log("Logueado");
          }
          else {
            console.log("dismissed");
          }
        });
        break;
      }
      case "register": {

        // Abrir pantalla de registro de usuario en forma modal
        const ref = this.dialogService.open(RegisterComponent, {
          width: '80%',
          showHeader: false,
          dismissableMask: true
        });

        ref.onClose.subscribe((userDo: boolean) => {
          if (userDo) {
            
          }
          else {
            
          }
        });
        break;
      }
      default:
        break;
    }
  }

}
