import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthInfo } from 'src/app/models/auth-info';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService, MessageType } from 'src/app/services/toast.service';

export interface FormPassInfo { password: string; repeatPassword: string; }

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  /**
    * Marca indicando componente cargado
    */
   public loaded: boolean;
 
   /**
    * Marca indicando operación en curso
    */
   public inuse: boolean;
 
   /**
    * Marca indicando primer uso
    */
   public submitted: boolean;
 
   /**
    * Datos del formulario de inicio de sesión
    */
   public form: FormGroup;

   public token: string;
 
   constructor(private authService: AuthService, 
     private sessionService: SessionService, 
     private router: Router, 
     private route: ActivatedRoute,
     private loadingService: LoadingService,
     private toastService: ToastService,
     private locale: LocalePipe) { }
 
   ngOnInit(): void {
 
     // establecer no cargado hasta inicialización de componente
     this.loaded = false;
 
     // inicializar indicador operación en curso
     this.inuse = false;
 
     // inicializar indicador formulario enviado
     this.submitted = false;
 
     // inicializar estructura del formulario
     this.form = this.generateForm();
 
     // establecer cargado tras inicialización de componente
     this.loaded = true;

     setTimeout(() => {
      this.loadingService.hideLoading();
    }, 500);

     if(this.route.snapshot.paramMap.get('token')) {
       this.route.params.subscribe((params: Params) => {
         this.token = params['token'];
        });
      }  
   }
 
   /**
    * Generar estructura del formulario
    * @return Estructura del formulario
    */
   private generateForm(): FormGroup {
 
     // retornar estructura del formulario aplicando validaciones
     return new FormGroup({
 
      // campo contraseña
      password: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(64)]),

      // campo contraseña
      repeatPassword: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(64)])
 
     });
   }
 
   /**
    * Finalizar recuperación de contraseña
    */
   public confirm(): void {
 
     // bloquear más cambios mientras operación en curso
     if (this.inuse) { return; }
 
     // omitir si datos del formulario incorrectos
     if (!this.form.valid) { return; }
 
     // marcar operación en curso durante ejecución
     this.inuse = true;
 
     // marcar formulario previamente enviado
     this.submitted = true;
 
     // recuperar datos
     const formInfo = this.form.value as FormPassInfo;
 
     // visualizar loader dinámico
     setTimeout(() => {
       this.loadingService.setLoading();
     });
 
     // llamar WS
     this.authService.confirmPassword(this.token, formInfo.password,
 
       // declarar función callback en caso de éxito
       (resp: AuthInfo) => {
 
         // ocultar loader dinámico
         setTimeout(() => {
           this.loadingService.hideLoading();
         });
 
         // marcar operación finalizada tras ejecución
         this.inuse = false;
 
         // Mensaje de ejecución ko
         this.toastService.newMessage(MessageType.success, this.locale.transform('confirm_ok'));
         
         // reenviar login
         this.router.navigate(['acceso']);
 
       },
 
       // declarar función callback en caso de error
       (resp: boolean, err: HttpErrorResponse) => { 
         
         // ocultar loader dinámico
         setTimeout(() => {
           this.loadingService.hideLoading();
         });
 
         // marcar operación finalizada tras ejecución
         this.inuse = false;
 
         // Mensaje de ejecución ko
         this.toastService.newMessage(MessageType.danger, this.locale.transform('confirm_ko'));
       }
     );
   }
}
