<div class="confirm-content">
    <div class="confirm-content-overlay">
        <div class="confirm-box card">
            <div class="card-body">
                <img src="../../../assets/img/logo_todo.png" routerLink="/inicio" alt="Credencial Digital Peregrino"/>
                <div class="title">Cambia tu clave de acceso validando una nueva contraseña</div>
                <div class="form-content">
                    <form [formGroup]="form" novalidate autocomplete="off" (submit)="confirm()">
                        <div class="item-line">
                            <!--vincular campo contraseña de usuario-->
                            <input [readOnly]="inuse" formControlName="password" id="passwordUsuario" placeholder="{{'register_passwordUsuario'|locale}}" type="password" required appPassword>
                            <!--pintar errores en campo si formulario enviado o campo modificado-->
                            <ng-container *ngIf="form.invalid && form.controls['password'].invalid && (submitted || form.controls['password'].dirty)">
                                <span *ngIf="form.controls['password'].errors?.required">{{'login_errors_required'|locale}}</span>
                                <span *ngIf="form.controls['password'].errors?.minlength">{{'login_errors_minlength'|locale}}</span>
                                <span *ngIf="form.controls['password'].errors?.maxlength">{{'login_errors_maxlength'|locale}}</span>
                            </ng-container>
                        </div>
                        <div class="item-line">
                            <!--vincular campo contraseña de usuario-->
                            <input [readOnly]="inuse" formControlName="repeatPassword" id="repeatPasswordUsuario" placeholder="{{'register_repeatPasswordUsuario'|locale}}" type="password" required appPassword>
                            <!--pintar errores en campo si formulario enviado o campo modificado-->
                            <ng-container *ngIf="form.invalid && form.controls['password'].invalid && (submitted || form.controls['password'].dirty)">
                                <span *ngIf="form.controls['password'].errors?.required">{{'login_errors_required'|locale}}</span>
                                <span *ngIf="form.controls['password'].errors?.minlength">{{'login_errors_minlength'|locale}}</span>
                                <span *ngIf="form.controls['password'].errors?.maxlength">{{'login_errors_maxlength'|locale}}</span>
                            </ng-container>
                        </div>
                       
                        <!--habilitar envío si formulario válido-->
                        <input [disabled]="inuse||!form.valid" value="{{'confirm_submit'|locale}}" type="submit">

                        <div class="signup-content">
                            <div>¿Ya tienes cuenta?<a routerLink="/acceso"><b>Inicia sesión</b></a></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>